const selectedProjectCookieName = 'ProjectId';
const projectConfigurationPath = '/Admin/Configuration';

const defaultProjectId = '975bf280-fd91-488c-994c-2f04416e5ee3';

export {
  selectedProjectCookieName,
  projectConfigurationPath,
  defaultProjectId,
};
